import React from 'react'
// function myplaceholder(props) {
//   return <div>{props.html}</div>;
// }

function createMarkup(props) {
  return {__html: (props.html)};
}

// function MyComponent(props) {
//   return <div dangerouslySetInnerHTML={createMarkup(props)} />;
// }
const RadioboxField = ({fieldClass, name, value, required, placeholder, handlechange, checked,step}) => (
      <div className={`form-group radio-wrap ${ step }`}>
      <label className="radio-label">
      <input type="radio" id={name+checked} name={name} value={value} onChange={handlechange}   />
      <span className="radio-ico"></span>
      {value}
      </label>
  </div>
);

export default RadioboxField;