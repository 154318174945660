import React, {  useContext } from "react";
import { Link } from "@StarberryUtils";
import { Col, Row, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { Plus } from '@Components/icon/icon';
import { POPULAR_SEARCH_HEADING } from "./constants";
import './index'
import { getUrlName } from "@Components/common/site/get-pages";
function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
      >
        {POPULAR_SEARCH_HEADING} {isCurrentEventKey ? '-' : <Plus /> }
      </button>
    );
  }
const PopularSearchAccordion = (props) => {


    let _props = {...props};

    let _col1Links = _props.col1Links || [];
    let _col2Links = _props.col2Links || [];
    let _col3Links = _props.col3Links || [];
    let _col4Links = _props.col4Links || [];

    // Remove (if any) empty items
    _col1Links = _col1Links.filter(e => e);
    _col2Links = _col2Links.filter(e => e);
    _col3Links = _col3Links.filter(e => e);
    _col4Links = _col4Links.filter(e => e);

   
    // if there is no links to show, then return
    if ( !(_col1Links && _col2Links && _col3Links && _col4Links ) ) {
        return null;
    }

    return (
        <div className="footer__top">
            <Accordion>
                <ContextAwareToggle eventKey="0" />
                <Accordion.Collapse eventKey="0">
                    <div className="accordion-content">
                        <Row>
                            <Col md="6" lg="3">
                                <ul>
                                {_col1Links && _col1Links.map((url, index) => {
                                    return (
                                        <li>
                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                {_col2Links && _col2Links.map((url, index) => {
                                    return (
                                        <li>
                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                {_col3Links && _col3Links.map((url, index) => {
                                    return (
                                        <li>
                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                        </li>
                                    )
                                })}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                {_col4Links && _col4Links.map((url, index) => {
                                        return (
                                            <li>
                                                <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Collapse>
            </Accordion>
    </div>
     
    )
}

export default PopularSearchAccordion;