import React, {  useContext } from "react";
import { Link } from "@StarberryUtils";
import { Col, Row, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { Plus } from '@Components/icon/icon';
import { POPULAR_SEARCH_HEADING } from "./constants";
import './index'
function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
      >
        {POPULAR_SEARCH_HEADING} {isCurrentEventKey ? '-' : <Plus /> }
      </button>
    );
  }
const PopularSearchAccordion = (props) => {
if(props.searchfileds == ""){
    return (null);
}
    
    const propertydetails = props.searchfileds
    var pType      = propertydetails.building
    var SearchType = propertydetails.search_type === 'sales'?'for Sale':'to Rent'
    var SearchArea = propertydetails.address.address3 && propertydetails.address.address3 ? propertydetails.address.address3 : propertydetails.address.address2
    var URL        = props.searchfileds.search_type === 'sales'?'/property/for-sale/':'/property/to-rent/'
    var typeURL    = (propertydetails.building!== '' && propertydetails.building!== 'property' ) ? '/type-'+propertydetails.building:''
    var Bedrooms   = propertydetails?.bedroom
     console.log("propertydetails",propertydetails)
    return (
        <div className="footer__top">
            <Accordion>
                <ContextAwareToggle eventKey="0" />
                <Accordion.Collapse eventKey="0">
                    <div className="accordion-content">
                        {/* {JSON.stringify(propertydetails)} */}
                        <Row>
                            <Col md="6" lg="3">
                                <ul>
                                <li><Link to={`${URL}in-manchester${typeURL}`}className="footer_scroll_top">{pType} {SearchType} in Manchester</Link></li>
                                <li><Link to={`${URL}in-deansgate${typeURL}`} className="footer_scroll_top">{pType} {SearchType} in Deansgate</Link></li>
                                <li><Link to={`${URL}in-salford${typeURL}`} className="footer_scroll_top">{pType} {SearchType} in Salford</Link></li>
                                <li><Link to={`${URL}in-hulme${typeURL}`} className="footer_scroll_top">{pType} {SearchType} in Hulme</Link></li>
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                {Bedrooms !== 1 ?
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/1-and-more-bedrooms`} className="footer_scroll_top">1 bedroom {pType} {SearchType} in {SearchArea}</Link></li> : null}
                                {Bedrooms !== 2 ?
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/2-and-more-bedrooms`} className="footer_scroll_top">2 bedroom {pType} {SearchType} in {SearchArea}</Link></li> : null}
                                {Bedrooms !== 3 ?
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/3-and-more-bedrooms`} className="footer_scroll_top">3 bedroom {pType} {SearchType} in {SearchArea}</Link></li> : null}
                                {Bedrooms !== 4 ?
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/4-and-more-bedrooms`} className="footer_scroll_top">4 bedroom {pType} {SearchType} in {SearchArea}</Link></li> : null}
                                {Bedrooms !== 5 ?
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/5-and-more-bedrooms`} className="footer_scroll_top">5 bedroom {pType} {SearchType} in {SearchArea}</Link></li> : null}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}`} className="footer_scroll_top">Property {SearchType} in {SearchArea}</Link></li>
                                {pType !== 'townhouse' ?<li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-townhouse`} className="footer_scroll_top">Town Houses {SearchType} in {SearchArea}</Link></li> : null}
                                {pType !== 'apartment' ?<li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-apartment`} className="footer_scroll_top">Apartments {SearchType} in {SearchArea}</Link></li> : null}
                                {pType !== 'house' ? <li><Link  to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-house`} className="footer_scroll_top">Houses {SearchType} in {SearchArea}</Link></li> : null}
                                <li><Link to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}`} className="footer_scroll_top">{pType} {SearchType} in {SearchArea}</Link></li>
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                    <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover">Manchester Area Guides</Link></li>
                                    <li><Link className="footer_scroll_top" to="/contact-manchester-estate-agents">Estate Agents in Manchester</Link></li>
                                    <li><Link className="footer_scroll_top" to="/about-us">About Bentley Hurst</Link></li>
                                    <li><Link className="footer_scroll_top" to="/property-valuation">Property Valuation in Manchester</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Collapse>
            </Accordion>
    </div>
     
    )
}

export default PopularSearchAccordion;
