import React, {useRef, useState} from 'react'
import { Form } from "react-bootstrap"
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

const InputField = ({ startDate, setStartDate, fieldClass, labelClass, min, max, type, name, value, required, placeholder, handlechange, pattern, label, grpmd,step, defaultValue }) => {
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef();

  // const toggleEditing = () => {
  //   setEditing(!isEditing);
  //   if (isEditing) {
  //     inputRef.current.focus();
  //   }
  // };
  return (
  <Form.Group md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
    <div className="custom-float">
      {label
        ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label>
        : ''
      }      
        <Form.Control
          className={fieldClass}
          required={required}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handlechange}
          pattern={pattern}
          autocomplete="off"
          defaultValue={defaultValue || ``}
        />

    </div>
  </Form.Group>
 );
};
export default InputField;
