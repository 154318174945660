import React, { useContext } from "react";
import { Link } from "@StarberryUtils";
import { Col, Row, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { Plus } from '@Components/icon/icon';
import { POPULAR_SEARCH_HEADING } from "./constants";
import './index'
function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
      >
        {POPULAR_SEARCH_HEADING} {isCurrentEventKey ? '-' : <Plus /> }
      </button>
    );
  }
const PopularSearchAccordion = (props) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }      
    var typeText   = props.searchfileds.type==null || props.searchfileds.type==='' ?'Property':capitalizeFirstLetter(props.searchfileds.type)
    var SearchType = props.searchfileds.pType === 'sales'?'for Sale':'to Rent'
    var SearchArea = props.searchfileds.areas
    var Price      = props.searchfileds.price
    var URL        = props.searchfileds.pType === 'sales'?'/property/for-sale/':'/property/to-rent/'
    var Bedrooms   = props.searchfileds.bedrooms
    var typeURL    = props.searchfileds.type!==''?'/type-'+props.searchfileds.type:''
    //console.log("props.searchfileds",props.searchfileds);
    return (
        <div className="footer__top">
            <Accordion>
                <ContextAwareToggle eventKey="0" />
                <Accordion.Collapse eventKey="0">
                    <div className="accordion-content">
                        <Row>
                            <Col md="6" lg="3">
                                {Bedrooms?
                                <ul>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-manchester/${Bedrooms}-and-more-bedrooms`}>
                                        {Bedrooms} bedroom property {SearchType} in Manchester
                                        </Link>
                                    </li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-deansgate/${Bedrooms}-and-more-bedrooms`}>
                                        {Bedrooms} bedroom property {SearchType} in Deansgate
                                        </Link>
                                    </li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-salford/${Bedrooms}-and-more-bedrooms`}>
                                        {Bedrooms} bedroom property {SearchType} in Salford
                                        </Link>
                                    </li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-hulme/${Bedrooms}-and-more-bedrooms`}>
                                        {Bedrooms} bedroom property {SearchType} in Hulme
                                        </Link>
                                    </li>                                                            
                                </ul>
                                :
                                <ul>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-manchester${typeURL}`}>{typeText} {SearchType} in Manchester</Link></li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-deansgate${typeURL}`}>{typeText} {SearchType} in Deansgate</Link></li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-salford${typeURL}`}>{typeText} {SearchType} in Salford</Link></li>
                                    <li><Link className="footer_scroll_top" to={`${URL}in-hulme${typeURL}`}>{typeText} {SearchType} in Hulme</Link></li>                                                            
                                </ul>
                                }
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                    
                                    {props.searchfileds.type ==='' && Bedrooms==='' && Price.max ==='' && Price.min ==='' && 
                                    <li>
                                        <Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}`}>Property {SearchType} in {capitalizeFirstLetter(SearchArea)}
                                        </Link>
                                    </li>
                                    }
                                    {props.searchfileds.type !=='' && Bedrooms ==='' &&
                                    <li>
                                        <Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}`}>Property {SearchType} in {capitalizeFirstLetter(SearchArea)}
                                        </Link>
                                    </li>
                                    }
                                    {Bedrooms !=='' && typeURL === '' &&
                                    <li>
                                        <Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}`}>Property {SearchType} in {capitalizeFirstLetter(SearchArea)}
                                        </Link>
                                    </li>
                                    }
                                    {Bedrooms !=='' && typeURL !== '' &&
                                    <li>
                                        <Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}`}>Property {SearchType} in {capitalizeFirstLetter(SearchArea)}
                                        </Link>
                                    </li>
                                    }
                                    
                                    {props.searchfileds.type !== 'townhouse' ? <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-townhouse`}>Townhouse {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {props.searchfileds.type !== 'apartment' ? <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-apartment`}>Apartments {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {props.searchfileds.type !== 'house' ? <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-house`}>Houses {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    { props.searchfileds.type==='' ? "" : 
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}/type-${typeText}`}>{typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li>}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                    {Bedrooms !== '1' ?
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/1-and-more-bedrooms`}>1 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {Bedrooms !== '2' ?
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/2-and-more-bedrooms`}>2 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {Bedrooms !== '3' ?
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/3-and-more-bedrooms`}>3 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {Bedrooms !== '4' ?
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/4-and-more-bedrooms`}>4 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li> : null}
                                    {Bedrooms !== '5' ? <>
                                    {typeURL !=='' && Bedrooms==='' &&
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/5-and-more-bedrooms`}>5 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li>
                                    }
                                    {Bedrooms !=='' && typeURL === '' &&
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/5-and-more-bedrooms`}>5 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li>
                                    }
                                    {Bedrooms !=='' && typeURL !== '' &&
                                    <li><Link className="footer_scroll_top" to={`${URL}in-${SearchArea?.replace(/\s/g , '-')}${typeURL}/5-and-more-bedrooms`}>5 bedroom {typeText} {SearchType} in {capitalizeFirstLetter(SearchArea)}</Link></li>
                                    }
                                    </> : null}
                                </ul>
                            </Col>
                            <Col md="6" lg="3">
                                <ul>
                                    <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover">Manchester Area Guides</Link></li>
                                    <li><Link className="footer_scroll_top" to="/contact-manchester-estate-agents">Estate Agents in Manchester</Link></li>
                                    <li><Link className="footer_scroll_top" to="/about-us">About Bentley Hurst</Link></li>
                                    <li><Link className="footer_scroll_top" to="/property-valuation">Property Valuation in Manchester</Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Collapse>
            </Accordion>
    </div>
     
    )
}

export default PopularSearchAccordion;