import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils"
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery'
import Starberry from "../../images/starberry.svg";
import "./Footer.scss";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import ContactForm from "@Components/forms/general-enquiry"
import CookieConsent from "react-cookie-consent"
import { CONTACTFORM_PAGE_URL } from "@Components/common/site/constants";
import PopularSearch from "@Components/popular-search/index";
import PopularSearchResults from "@Components/popular-search/popular-search-results";
import PopularSearchDetail from "@Components/popular-search/popular-search-detail";
import file from "../../../public/file/Propertymark_CMP_Main_Scheme_Certificate_2022.pdf"
import file2 from "../../../public/file/Propertymark_CMP_Security_Certificate_2022.pdf"
import file3 from "../../../public/file/BH_Complaints_Procedure.pdf"
import { getSrc } from "gatsby-plugin-image"
// import loadable from "@loadable/component";
// const ContactForm = loadable(() => import('@Components/forms/general-enquiry'))
const Footer = (props) => {
    const [showIframe, setshowIframe] = useState(false)
    useEffect(() => {
        $(".footer_scroll_top").click(function () {
            $("html, body").scrollTop(0);
        })
        const script = document.createElement('script');
  
        script.src = "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href
        script.defer = true;
        script.async = true;
      
        document.body.appendChild(script);
    }, [])
    const year = new Date().getFullYear();
    const data = useStaticQuery(graphql`
    query{
        glstrapi {
            footer(publicationState: LIVE) {
        Footer_Content
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Office_Details
        Show_Clients
        Twitter_Link
        Youtube_Link
        Clients {
            Client_Url
            Client_Logo{
                alternativeText
                url
                url_sharp {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO
                        width: 180
                        quality: 80
                        layout: FIXED
                        transformOptions: {cropFocus: CENTER, fit: COVER}
                      )
                    }
                  }
            }
          }
      }
    }
}
    `);

    return (
        <footer className="footer">
            <Container>
                {props.popularsearch === "searchresults" &&
                    <PopularSearchResults searchfileds={props.searchParams} />
                }
                {props.popularsearch === "propertydetails" &&
                    <PopularSearchDetail searchfileds={props.propertydetails} />
                }
                {props.popularsearch !== "searchresults" && props.popularsearch !== "propertydetails" &&
                    <PopularSearch {...props} />
                }
                <Row>
                    <Col lg="6">
                        <Row>
                            <Col lg="12">
                                <div className="footer-form-title">
                                    <div className="rich-text-editer-block">
                                        <ReactMarkdown source={data.glstrapi?.footer?.Footer_Content} allowDangerousHtml />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="footer-form">
                            <ContactForm formname={true} />

                        </div>
                    </Col>
                    <Col lg="6" className="d-xl-flex">
                        <div className="office-details">

                            <h2>Office Details</h2>
                            <div className="rich-text-editer-block">
                                <ReactMarkdown source={data.glstrapi?.footer?.Office_Details} allowDangerousHtml />
                                <p><a href="tel:0161 543 0310">0161 543 0310</a><br />
                                    <Link to={CONTACTFORM_PAGE_URL.alias}>hello@bentleyhurst.co.uk</Link></p>
                            </div>
                            <div class="fixed-btns d-flex align-items-center justify-content-between d-lg-none property-details-mobile-btn allpage-mobile-btn"><a class="dark-bg btn btn-primary d-md-none" href="/property-valuation">Book a valuation</a></div>
                        </div>
                        <div className="social-media">
                            <h2>Connect with us</h2>
                            <div className="social-media-icons">
                                {data.glstrapi?.footer?.Facebook_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Facebook_Link} className="social-icon social-icon--facebook"><i className="icon-facebook"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Instagram_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Instagram_Link} className="social-icon social-icon--instagram"><i className="icon-instagram"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Twitter_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Twitter_Link} className="social-icon social-icon--twitter"><i className="icon-twitter"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Youtube_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Youtube_Link} className="social-icon social-icon--youtube"><i className="icon-youtube"></i></Link>
                                    : ''}
                                {data.glstrapi?.footer?.Linkedin_Link ?
                                    <Link target="_blank" href={data.glstrapi?.footer?.Linkedin_Link} className="social-icon social-icon--linkedin"><i className="icon-linkedin"></i></Link>
                                    : ''}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="copyright-block">
                            <p className="footer-link">
                                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                                <Link to="/sitemap">Sitemap</Link>
                                <Link to="/cookie-policy">Cookie Policy</Link>
                                <a target="_blank" href={file}>Client Money Protection Certificate</a>
                                <a target="_blank" href={file2}>Client Money Protection</a>
                                <a target="_blank" href={file3}>Complaints Procedure</a>
                                <Link to="/payment-diversion-fraud">Payment Diversion Fraud</Link>
                                <a target="_blank" href={'http://www.propertymark.co.uk/professional-standards/rules.html'}>Propertymark Conduct and Membership Rules</a>
                            </p>

                            <p>{year} Bentley Hurst Estate Agents. All Rights Reserved.<br /> Site by <a href={'http://starberry.tv/'} target="_blank"><img src={Starberry} alt="Starberry" className="stb-logo" /></a></p>
                        </div>
                    </Col>
                    <Col lg="7">
                        {data.glstrapi?.footer?.Show_Clients ?
                            <div className="logo-block">
                                {data.glstrapi?.footer?.Clients.map((node, i) => (
                                    <a href={node.Client_Url} target="_blank" >
                                        <img src={getSrc(node.Client_Logo?.url_sharp)} alt={node.Client_Logo?.alternativeText} />
                                    </a>
                                ))}
                            </div>
                            : ''}
                    </Col>
                </Row>

                <CookieConsent
                    disableStyles={true}
                    buttonClasses="btn btn-primary"
                    containerClasses="cookie-popup d-lg-flex d-block"
                    contentClasses=""
                    buttonClasses="btn btn-link gotit"
                    buttonText="Accept Cookies">

                    <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.</p>
                </CookieConsent>

            </Container>
        </footer>
    )
}
export default Footer;
