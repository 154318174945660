import React from "react";

import {
    PAGE_TYPE,
    POPULAR_SEARCH_TYPE
} from "./constants";
import PopularSearchStaticDefault from './static/default';
import PopularSearchStaticDefault2 from './static/default2';
import PopularSearchStaticDefault3 from './static/default3';
import PopularSearchStaticDefault4 from './static/default4';
import PopularSearchStaticDefault5 from './static/default5';

import {
    isAboutPages,
    isContactPage,
    isSellPages,
    isRentPages,
} from "@Components/common/site/get-pages";

const PopularSearch = (props) => {

    // console.log("Inside PopularSearch");

    let _props = {...props} || {};

    // if ( _props && Object.keys(_props).length > 0 ) {
        // console.log('_props, ' + _props);
        // console.log( Object.keys(_props) );
        // console.log( Object.values(_props) );
    // }
    
    let popularSearchType = _props.popularSearchType || POPULAR_SEARCH_TYPE['DEFAULT'];
    popularSearchType = popularSearchType.trim().toLowerCase();
    // console.log('popularSearchType : ' + popularSearchType);

    let pageType = _props.pageType || PAGE_TYPE['STATIC'];
    pageType = pageType.trim().toLowerCase();
    // console.log('pageType : ' + pageType);

    const renderPopularSearchItems = () => {
        // console.log("Inside function renderPopularSearchItems()");

        if ( isSellPages() ) {
            pageType = PAGE_TYPE['STATIC'];
            popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT2'];
        } else if ( isRentPages() ) {
            pageType = PAGE_TYPE['STATIC'];
            popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT3'];
        
        } else if ( isAboutPages() ) {
            pageType = PAGE_TYPE['STATIC'];
            popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT4'];
        } else if ( isContactPage() ) {
            pageType = PAGE_TYPE['STATIC'];
            popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT5'];
        // } else if ( isOfficePages() ) {
        //     pageType = PAGE_TYPE['STATIC'];
        //     popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT6'];
        // } else if ( isAreaGuidesDetailsPage() ) {
        //     pageType = PAGE_TYPE['DYNAMIC'];
        //     popularSearchType = POPULAR_SEARCH_TYPE['AREA_GUIDE_DETAILS'];
        // } else if ( isPropertiesResultsPage() ) {
        //     pageType = PAGE_TYPE['DYNAMIC'];
        //     popularSearchType = POPULAR_SEARCH_TYPE['PROPERTIES_RESULTS_DEFAULT'];
        // } else if ( isPropertyDetailsPage() ) {
        //     pageType = PAGE_TYPE['DYNAMIC'];
        //     popularSearchType = POPULAR_SEARCH_TYPE['PROPERTY_DETAILS_DEFAULT'];
        } 
        else {
            pageType = PAGE_TYPE['STATIC'];
            popularSearchType = POPULAR_SEARCH_TYPE['DEFAULT'];    
        }
        // console.log('pageType : ' + pageType);
        // console.log('popularSearchType : ' + popularSearchType);

        if ( PAGE_TYPE['STATIC'] === pageType ) {
            switch (popularSearchType) {
                case POPULAR_SEARCH_TYPE['DEFAULT']:
                    return <PopularSearchStaticDefault {..._props} />;
                case POPULAR_SEARCH_TYPE['DEFAULT2']:
                    return <PopularSearchStaticDefault2 {..._props} />;   
                case POPULAR_SEARCH_TYPE['DEFAULT3']:
                    return <PopularSearchStaticDefault3 {..._props} />;   
                case POPULAR_SEARCH_TYPE['DEFAULT4']:
                    return <PopularSearchStaticDefault4 {..._props} />;   
                case POPULAR_SEARCH_TYPE['DEFAULT5']:
                    return <PopularSearchStaticDefault5 {..._props} />;   
            }
        // } else if ( PAGE_TYPE['DYNAMIC'] === pageType ) {
        //     switch (popularSearchType) {
        //         case POPULAR_SEARCH_TYPE['PROPERTIES_RESULTS_DEFAULT']:
        //             return <PopularSearchDynamicPropertiesResultsDefault {..._props} />;
        //         case POPULAR_SEARCH_TYPE['PROPERTY_DETAILS_DEFAULT']:
        //             return <PopularSearchDynamicPropertyDetailsDefault {..._props} />;
        //         case POPULAR_SEARCH_TYPE['AREA_GUIDE_DETAILS']:
        //             return <PopularSearchDynamicAreaGuideDetails {..._props} />;
        //     }
        }
        return <PopularSearchStaticDefault {..._props} />; // default popular search
    }

    return (
        <div class="component-PopularSearch">
            {renderPopularSearchItems()}
        </div>
    )

}
export default PopularSearch;