import React,{useState} from "react"
import {Nav, Container,Row,Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import  './SearchBlock.scss';
import GetURL from '@Components/common/site/get-url';


const SearchBlock = ( props ) => {
  const data = useStaticQuery(graphql`
  query{
    allStrapiMenuBurgers(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
      edges {
        node {
          Menu_Parent {
            Parent_Label
            Parent_URL {
              Alias
            }
          }
          Parent_Burger_Menu {
            Parent_Label
            Parent_URL {
              Alias
            }
          }
        }
      }
    }

    strapiMiniBurgerMenu {
      Title
      Description
      Add_Mini_Burger_Menu {
        Menu_Label
        URL {
          Alias
        }
        Mail_Parent {
          URL
          Alias
          Label
        }
      }
      Add_Cta {
        Cta_Label
        Cta_URL {
          Alias
        }
      }
    }

  }
  `);

  const [state,setState] = useState(data.allStrapiMenuBurgers.edges)

  const [issubmenu,setIssubmenu] = useState([])
  const [click,setClick] = useState(false)
  const [isOpen, setisOpen] = useState(null)


  const handlerClick = (item,index) => {
    
    if(item.Parent_Burger_Menu.length>0){
      if(index === isOpen){
        setisOpen(null)
      }
      else {
        setisOpen(index)
      }
    }
    else{
      setisOpen(null)
    }
  }
  return(
    // <StyleRoot>
      <div className="search-block" 
      // style={props.showMenu ? styles.slideIn : styles.slideInUp}
      >
        <Container>
          <div className="search-wrap" >
            <div></div>
            <Row>
              <Col xl="9">
              <Nav className="search-nav">
                {/* <ScrollAnimation animateIn='fadeIn' animateOnce={true}> */}
              <ul>
              {
               
                state.map((item,i)=>
                  <li onClick={()=>handlerClick(item.node,i)}>
                    {/* <Link href={item.node?.Menu_Parent?.Parent_URL?.Alias}>{item.node?.Menu_Parent?.Parent_Label} {item.node?.Parent_Burger_Menu?.length > 0 && <i className={isOpen === i ? "list-icon list-icon--minus" : "list-icon list-icon--plus"}></i>}</Link> */}
                    {item.node?.Menu_Parent?.Parent_Label === 'Auctions' ? <><a href="javascript:void(0)">{item.node?.Menu_Parent?.Parent_Label}</a><i className={isOpen === i ? "list-icon list-icon--minus" : "list-icon list-icon--plus"}></i></> :  <GetURL label={item.node?.Menu_Parent?.Parent_Label} alias={item.node?.Menu_Parent?.Parent_URL?.Alias} isplus={item.node?.Parent_Burger_Menu?.length > 0 ? true : false} isOpen={isOpen} i={i}  />}
                    <div className={isOpen === i ? "dropdown-link open" : "dropdown-link"}>
                        <ul>
                        {
                          item.node?.Parent_Burger_Menu && 
                          item.node?.Parent_Burger_Menu.map((newitem, j)=>
                            <li>
                              <GetURL label={newitem.Parent_Label} alias={newitem.Parent_URL?.Alias} />
                              {/* <Link href={newitem.Parent_URL?.Alias}>{newitem.Parent_Label}</Link> */}
                            </li>
                          )
                        }
                        </ul>
                      </div>
                   </li>
                )
              }
              </ul>
              {/* </ScrollAnimation> */}
           </Nav>
           </Col>
              <Col xl="3">
              <div className={issubmenu.length===0 && isOpen === null ? "navigation-link-block" : "navigation-link-block d-lg-none"}>
                {/* <Nav className="search-link-list">
                  <ul>
                  {data.strapiMiniBurgerMenu?.Add_Mini_Burger_Menu.map((item, index) => {
                    return(
                      <li>
                      <Link href={item?.Mail_Parent !== null ? `/${item?.Mail_Parent?.URL}/${item.URL?.Alias}/` : `/${item.URL?.Alias}/`}> {item.Menu_Label}</Link>
                    </li>
                
                    )
                  })
                }
                </ul>
                </Nav> */}
                <div className="d-sm-block d-md-block search-text-block">
                  <strong>{data.strapiMiniBurgerMenu?.Title}</strong>
                  <p>{data.strapiMiniBurgerMenu?.Description}</p>
                  {data.strapiMiniBurgerMenu?.Add_Cta?.Cta_Label && data.strapiMiniBurgerMenu?.Add_Cta?.Cta_URL?.Alias &&
                  <GetURL label={data.strapiMiniBurgerMenu?.Add_Cta?.Cta_Label} alias={data.strapiMiniBurgerMenu?.Add_Cta?.Cta_URL?.Alias} class={'btn btn-primary dark-bg'} />
                    
                  }
                </div>
                
              </div>
            </Col> 
         </Row>
         
         </div>
        </Container>
        </div>
    // </StyleRoot>
    )
  }



export default SearchBlock