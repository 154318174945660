import React, {useState} from 'react';
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import { FORSALE_Manchester_PAGE_URL, TORENT_Manchester_PAGE_URL } from "@Components/common/site/constants";
/**
 * Assets
 */
const GetURL = (props) => {

  const [hoverclass,setHoverclass] = useState(false)

    const data = useStaticQuery(graphql`
    query{

      glstrapi {
        allMenus(publicationState: LIVE) {
          Alias
          Secondary_URL
          URL
          External_URL
          Label
          Main_Parent {
            Alias
            URL
          }
          Sub_Parent {
            Alias
            URL
          }
          Link_Type
        }
      }

    }
  `);

  const PageURL = data.glstrapi?.allMenus.filter(item => item?.Alias === props?.alias).pop();

  let URL = PageURL?.Secondary_URL ? PageURL?.Secondary_URL : PageURL?.URL
  if((PageURL?.Main_Parent != null) &&(PageURL?.Sub_Parent == null)) {
    URL = PageURL?.Main_Parent?.URL + '/' +URL
  }

  else if((PageURL?.Main_Parent != null) &&(PageURL?.Sub_Parent != null)) {
    if(props.submain){
      URL = PageURL?.Main_Parent?.URL + '/' + props.submain + '/' +URL
    }
    else {
      URL = PageURL?.Main_Parent?.URL + '/' + PageURL?.Sub_Parent?.URL + '/' +URL
    }

  }

    return (
    <>
    
         {PageURL?.Link_Type === 'Internal' && <>
         {URL === "property/for-sale" ? <Link to={FORSALE_Manchester_PAGE_URL.alias} className={props.class}>
         {props.label} 
            
          </Link> :
                      URL === "property/to-rent" ? <Link to={TORENT_Manchester_PAGE_URL.alias} className={props.class}>
                      {props.label} 
                      
                    </Link> :
                    
          <Link to={`/${URL}`} className={props.class}>
            {props.isLinkArrow ? <span className={hoverclass ? "red btn-arrow" : "btn-arrow"} onMouseEnter={() => setHoverclass(true)} onMouseLeave={() => setHoverclass(false)}><i className="icon-arrow"></i></span> : props.isArrow ? <span className="btn-arrow btn-arrow-bg"><i className="icon-arrow"></i></span> : null}
              {props.label} 
          </Link>
          }
          {props.isplus ? <i className={props.isOpen === props.i ? "list-icon list-icon--minus" : "list-icon list-icon--plus"}></i> : null}
          </>
        }
        {PageURL?.Link_Type === 'External_New_Window' &&<>
        <a href={PageURL?.External_URL} target="_blank" className={props.class}>
        {props.label}
        </a>
        </>
        }


    </>
    )
}

export default GetURL

// Specifies the default values for props:
GetURL.defaultProps = {
    label: '',
    class: '',
    arrow: 'no',
    dropdown: 'no',
    style: '',
    URL: '',
    icon: ''
};
