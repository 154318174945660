import axios from 'axios';
import { getToken, getUser } from '@myaccount/website-service';

const baseURL = `${process.env.GATSBY_STRAPI_FORM_URL}`;
const token = getToken() || process.env.GATSBY_STRAPI_FORM_TOKEN;
const leadproKey = process.env.GATSBY_LEADPRO_API_KEY || null;
const userData = getUser();


const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  },
});


export const postFileData = async (data) => {

  const res = await authInstance.post('upload', data)
    .then((res) => {
      // Success
      if (res.statusText === 'OK') {
        return {
          success: true,
          ...res.data,
        }
      }
      return { success: false }
    })
    .catch((error) => {
      // Failed
      if (error.response) {
        return {
          success: false,
          message: error.response.data,
        }
      } else {
        // Service error
      }
    })

  return res;

}

export const postFormData = async (data) => {

  let formData = JSON.parse(data.get('data'));
  const propertyData = JSON.parse(data.get('propertyData'));

  if (userData?.uid) {
    formData['user_id'] = userData.uid;
    formData['contact_id'] = userData.contact_id;
    formData['authUser'] = true;
  }

  // console.log(propertyData)

  if ( leadproKey && (formData.formname === "Valuation" || formData.formname === "Book a Viewing")) {

    const valuationType = formData?.sellorlet === 'Sell' && 'vendor' || formData?.sellorlet === 'Let' && 'landlord'

    const viewingType = propertyData?.search_type === "lettings" && 'let' || propertyData?.search_type === "sales" & 'sale'

    const leadType = propertyData ? (viewingType ? viewingType : 'sale') : (valuationType ? valuationType : 'sale')

    const address = propertyData ? propertyData?.display_address : formData?.address

    const addressPostalCode = formData?.addressPostalCode ? formData?.addressPostalCode : '';

    const lead = {
      "type": leadType,
      "phone":  formData?.telephone,
      "first_name": formData?.name,
      "last_name": formData?.lastName,
      "email": formData?.email,
      "address": address,
      "postcode": addressPostalCode,
      "office_id": 6002,
      "meta":{
        'formname': formData?.formname,
        'market': formData?.market,
        'mortgage': formData?.mortgage,
        'propertysell': formData?.propertysell,
        'purchase': formData?.purchase,
        'url': formData?.url,
        'date': formData?.date_time,
        'display_address': formData?.display_address,
        'email_temp_admin': formData?.email_temp_admin,
        'email_temp_user': formData?.email_temp_user,
      }

    }

    // console.log(lead)

  const leadproInstance = await axios.post('https://api.lead.pro/api/v103/leads',lead,{
      headers: {
        'X-API-KEY': leadproKey
      }
    })
    .then((res) => {
      console.log(res, 'success')
    })
    .catch((error) => {
      console.log("error", error)
    })
  }

  data.set('data', JSON.stringify(formData));

  const res = await authInstance.post('forms', data)
  .then((res) => {
    // Success
    // console.log("form sent to strapi")
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })

  return res;

}
