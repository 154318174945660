import React from "react";

// import { addLinksToArray } from "@Components/common/site/utils";
import {
    FORSALE_Manchester_PAGE_URL,
    FORSALE_Deansgate_PAGE_URL,
    FORSALE_Salford_PAGE_URL,
    FORSALE_Hulme_PAGE_URL,
    TORENT_Manchester_PAGE_URL,
    TORENT_Deansgate_PAGE_URL,
    TORENT_Salford_PAGE_URL,
    TORENT_Hulme_PAGE_URL,
    ABOUT_PAGE_URL,
    VALUATION_PAGE_UEL,
    MARKET_YOUR_PROPERTY_PAGE_URL,
    PROPERTY_SERVICES_PAGE_URL,
    TEAM_PAGE_URL,
    CAREERS_PAGE_URL,
    AREA_GUIDES_PAGE_URL,
    REVIEWS_PAGE_URL
} from "@Components/common/site/constants";
// import { SEARCH_TYPES } from "@Components/common/property/constants";

// import { areasUrls } from "../utils";
import PopularSearchAccordion from "../accordion";

const PopularSearchStaticDefault5 = (props) => {
    
    let _props = {...props};

    // let _areasSalesUrls = areasUrls({searchType: SEARCH_TYPES.SALES});

    let col1Links = [];
    col1Links.push(FORSALE_Manchester_PAGE_URL);
    col1Links.push(FORSALE_Deansgate_PAGE_URL);
    col1Links.push(FORSALE_Salford_PAGE_URL);
    col1Links.push(FORSALE_Hulme_PAGE_URL);
    
    
    let col2Links = [];
    col2Links.push(TORENT_Manchester_PAGE_URL);
    col2Links.push(TORENT_Deansgate_PAGE_URL);
    col2Links.push(TORENT_Salford_PAGE_URL);
    col2Links.push(TORENT_Hulme_PAGE_URL);
    

    let col3Links = [];
    col3Links.push(ABOUT_PAGE_URL);
    col3Links.push(VALUATION_PAGE_UEL);
    col3Links.push(MARKET_YOUR_PROPERTY_PAGE_URL);
    col3Links.push(PROPERTY_SERVICES_PAGE_URL);

    let col4Links = [];
    col4Links.push(TEAM_PAGE_URL);
    col4Links.push(CAREERS_PAGE_URL);
    col4Links.push(AREA_GUIDES_PAGE_URL);
    col4Links.push(REVIEWS_PAGE_URL);
    
    _props.col1Links = col1Links;
    _props.col2Links = col2Links;
    _props.col3Links = col3Links;
    _props.col4Links = col4Links;

    return (
        <div class="component-PopularSearchStaticDefault2">
            <PopularSearchAccordion {..._props} />
        </div>
    )
}

export default PopularSearchStaticDefault5;