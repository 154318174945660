export const POPULAR_SEARCH_HEADING = 'Popular Searches';

export const PAGE_TYPE = {
    STATIC: 'static',
    DYNAMIC: 'dynamic'
}

export const POPULAR_SEARCH_TYPE = {
    DEFAULT: 'default',
    DEFAULT2: 'default2',
    DEFAULT3: 'default3',
    DEFAULT4: 'default4',
    DEFAULT5: 'default5',
    PROPERTIES_RESULTS_DEFAULT: 'properties-results-default',
    PROPERTY_DETAILS_DEFAULT: 'property-details-default',
    AREA_GUIDE_DETAILS: 'area-guide-details'
}

export const POPULAR_SEARCH_BED_ROOM_LABEL = 'bedroom';
export const POPULAR_SEARCH_BED_ROOMS_LABEL = 'bedrooms';
export const POPULAR_SEARCH_BED_ROOMS_NUMBERS = [1,2,3,4,5];