import { Link } from "@StarberryUtils"
import React, { useState, useEffect } from "react"
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import SearchBlock from "../SearchBlock/SearchBlock"
import { StyleRoot } from 'radium';
// Images
import BrandLogoColor from "../../images/logo-color.svg"
import BrandLogoInner from "../../images/logo-inner.svg"
import './Header.scss';
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import GetURL from '@Components/common/site/get-url';
import $ from "jquery";
import SearchOverlay from "../searchOverlay/SearchOverlay"

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = (props) => {
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      menuHeaders(publicationState: LIVE, sort:"Sort:asc") {
        Parent_Label
        Sort
        Sub_Menus {
          Add_Sub_Menus {
            Label
            URL {
              Alias
            }
          }
          Parent_Label
          Parent_URL {
            Alias
          }
        }
        Publish
        Parent_URL {
          Alias
        }
      }


      areaGuides(publicationState: LIVE, sort:"published_at:desc") {
        Title
        URL
        Publish
      }



    }

  }
`);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // if (props.disableFixed) {

      // }
      // else {
        setScroll(window.scrollY > 200)
      // }
    })
  }, [])

  // Search
  function searchOverlay(e) {
    $("body")
      .addClass("modal-open")
      .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }
  // Search
  // useEffect(() => {
  //   const script = document.createElement('script');
  
  //   script.src = "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href
  //   script.defer = true;
  //   script.async = true;
  
  //   document.body.appendChild(script);
  
  //   // return () => {
  //   //   document.body.removeChild(script);
  //   // }
  // }, []);
  return (
    <>
      <SearchOverlay />
      <header className={scroll ? "header--fixed header" + " "+props.searchpage : "header" + " "+props.searchpage}>
        <Container fluid className="d-flex justify-content-between ">
          <Nav>
            <Navbar className="p-0" expand="lg">
                <Link className="navbar-brand" to="/">
                <StyleRoot>
                  <picture
                  >
                    <source media="(min-width:992px)" srcset={BrandLogoColor} />
                    <img className="logo" src={BrandLogoColor} alt="Logo" />
                  </picture>
                </StyleRoot>
                <img className="logo logo--second" src={BrandLogoColor} alt="Logo" />
                <img className="logo logo-inner" src={BrandLogoInner} alt="Logo" />
                </Link>
              <Navbar.Collapse className="d-none navigation-wrap">
                <Nav>
                  <div className="navigation">
                    <ul>
                      {data.glstrapi.menuHeaders.map((node, i) => (
                        <li key={i}>
                          {/* {JSON.stringify(node)} */}
                          {/* {node.Sub_Menus?.length === 0 ? */}
                          {/* {node.Parent_URL.Alias === 'for-sale' ?
                                              <Link to={FORSALE_Manchester_PAGE_URL.alias} >
                                                {node.Parent_Label}</Link> : */}
                          <GetURL label={node?.Parent_Label} alias={node?.Parent_URL?.Alias} />
                          {/* } */}
                          {/* :
                          <Link>{node.Parent_Label}</Link>
                          } */}
                          {node.Sub_Menus.length !== 0 &&
                            <div className={node.Sort === 1 ? "single-mega-menu mega-menu" : node.Sort === 3 ? "mini-mega-menu mega-menu" : "mega-menu"}>
                              <div className="mega-menu-wrap">
                                {node.Sort === 1 ?
                                  <Row>
                                    <Col lg="12" key={i}>
                                      <ul>
                                        {node.Sub_Menus.map((Sub_Menus, i) => (

                                          Sub_Menus.Parent_Label != null && Sub_Menus.Parent_URL.Alias != null &&
                                          <li>
                                              {/* {Sub_Menus.Parent_URL?.Alias === 'for-sale' ?
                                              <Link to={FORSALE_Manchester_PAGE_URL.alias} >
                                                {Sub_Menus.Parent_Label}</Link> :
                                              Sub_Menus.Parent_URL?.Alias === 'to-rent' ?
                                                <Link to={TORENT_Manchester_PAGE_URL.alias} >
                                                  {Sub_Menus.Parent_Label}</Link> : */}
                                            <GetURL label={Sub_Menus.Parent_Label} alias={Sub_Menus.Parent_URL.Alias} />
                                            {/* } */}
                                            </li>  
                                          
                                          // <h3><Link to={'/' + node.Parent_URL.Alias + '/' + Sub_Menus.Parent_URL.Alias}>{Sub_Menus.Parent_Label}</Link></h3>


                                        ))}                                            
                                      </ul>
                                    </Col>

                                  </Row>
                                  : node.Sort === 3 ?
                                    <Row>
                                      <Col lg="12" key={i}>
                                        <ul>

                                          {node.Sub_Menus.map((Sub_Menus, i) => (
                                            Sub_Menus.Parent_Label != null && Sub_Menus.Parent_URL.Alias != null &&
                                            <li><GetURL label={Sub_Menus.Parent_Label} alias={Sub_Menus.Parent_URL.Alias} /></li>

                                          ))}
                                        </ul>

                                      </Col>

                                    </Row>
                                    :
                                    <Row>
                                      {node.Sub_Menus.map((Sub_Menus, i) => (
                                        <Col lg="4" key={i}>
                                          {Sub_Menus.Parent_Label != null && Sub_Menus.Parent_URL.Alias != null &&
                                            <p><b>{Sub_Menus.Parent_Label === 'Auctions' ? Sub_Menus.Parent_Label : <GetURL label={Sub_Menus.Parent_Label} alias={Sub_Menus.Parent_URL.Alias} />}</b></p>
                                            // <h3><Link to={'/' + node.Parent_URL?.Alias + '/' + Sub_Menus.Parent_URL?.Alias}>{Sub_Menus.Parent_Label}</Link></h3>
                                          }
                                          {/* {Sub_Menus.Parent_URL.Alias !== 'areas-we-cover' && */}
                                          <ul>
                                            {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => (
                                              <li>
                                                <GetURL label={Add_Sub_Menus?.Label} alias={Add_Sub_Menus?.URL?.Alias} />
                                                {/* <Link to={'/' + node.Parent_URL?.Alias + '/' + Sub_Menus?.Parent_URL?.Alias + '/' + Add_Sub_Menus?.URL?.Alias}>{Add_Sub_Menus?.Label}</Link> */}
                                              </li>
                                            ))}
                                          </ul>
                                          {/* } */}

                                        </Col>
                                      ))}
                                    </Row>
                                }
                              </div>
                            </div>
                          }
                        </li>
                      ))}
                       <li class="valuation-btn"><Link to={'/property-valuation/'} class="btn btn-primary">Valuation</Link></li>
                    </ul>
                  </div>

                </Nav>

              </Navbar.Collapse>
              <div className="nav-right ml-auto">
                <a href={myaccountURL} className="nav-right-link sb-myacc"><i className="icon-mobile"></i> My Account</a>
                <Form inline>
                  <div className="search-input">
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                  </div>
                  <Button variant="link" className="btn-search" onClick={searchOverlay}><i className="icon-search"></i></Button>
                </Form>
                <Button className="mune-icon" onClick={props.handlerClick} variant="link">
                  <div id="nav-icon4">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Button>
              </div>
            </Navbar>
          </Nav>
        </Container>

  <Helmet>
  <script type="application/ld+json">{`{
 "@context": "http://schema.org/",
  "@type": "WebSite",
  "name": "Bentley Hurst - Estate Agents in Manchester",
  "url": "https://www.bentleyhurst.co.uk/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.bentleyhurst.co.uk//search?q={search_term_string}",
    "query-input": "required name=search_term_string"
  }
 }`}</script>

<script type="application/ld+json">{`{
"@context":"http://schema.org",
"@type": "organization",
"name": "Bentley Hurst - Estate Agents Manchester",
 "logo" : "https://www.bentleyhurst.co.uk/images/mail-logo.png",
 "url": "https://www.bentleyhurst.co.uk/",
"telephone": ["0161 543 0310"],
"description": "Buy, sell and rent property in Manchester and the surrounding areas with Bentley Hurst, experienced property professionals with the latest technology",
 "address" : {
    "@type" : "PostalAddress",
    "streetAddress" : "35B Whitworth Street West",   
    "addressLocality" : "Manchester",  
    "postalCode" : "M1 5ND"
  },
 "openingHoursSpecification" : {
    "@type" : "OpeningHoursSpecification",
    "dayOfWeek" : {
      "@type" : "DayOfWeek",
      "name" : "Mo, Tu, We, Th, Fr 09:00-17:30 Sa 09:00-16:00 su 10:00-14:00"
    }
  },
"sameAs": [
 "https://www.facebook.com/BentleyHurstLTD/",
 "https://www.instagram.com/bentley.hurst", 
 "https://www.linkedin.com/company/bentley-hurst",
 "https://www.youtube.com/channel/UCbok1WpAXT-I1586sYJRXbw",
 "https://twitter.com/bentley_hurst"
]
}`}</script>
 </Helmet>
      </header>
      <div className="search-section">

        <SearchBlock showMenu={props.showMenu} />
      </div>
    </>
  )
}



export default Header